<template>
  <div>Loading</div>
</template>

<script>
export default {
name:"Loading"
}
</script>

<style>

</style>